import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import '../App.css'; // Certifique-se de que o caminho está correto

function Home() {
  const [editions, setEditions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const fetchEditions = async () => {
      try {
        const response = await axios.get(`https://scoreboard-j6oz.onrender.com/editions/summary?page=${page}`);

        setEditions(prevEditions => {
          const newEditions = response.data.filter(
            edition => !prevEditions.some(e => e.number === edition.number)
          );
          return [...prevEditions, ...newEditions];
        });

        setLoading(false);
      } catch (error) {
        console.error('Error fetching editions:', error);
        setLoading(false);
      }
    };

    if (loading) {
      fetchEditions();
    }
  }, [loading, page]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPosition = window.scrollY || window.pageYOffset || document.body.scrollTop + (document.documentElement && document.documentElement.scrollTop || 0);

      if (documentHeight - windowHeight - scrollPosition < 100) {
        setPage(prevPage => prevPage + 1);
        setLoading(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="App">
      <nav className="navbar navbar-dark bg-primary">
        <div className="container">
          <button className="navbar-toggler" type="button" onClick={toggleMenu}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand" href="#">
            <b>Rhymes Score</b>
          </a>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/topbigfour">Top 5 - Big Four - São Paulo SP</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/rankhype">Ranking do Hype - Big Four - São Paulo SP</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">Sobre Nós</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Conteúdo principal que vai crescer conforme o necessário */}
      <div className="main-content">
        <div className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="editions-title">Últimas Edições</h2>
              </div>
            </div>
          </div>
        </div>

        {editions.map((edition, index) => (
          <div className="py-3" key={index}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card bg-light p-3">
                    <div className="row mb-4">
                      <div className="col-3 d-flex flex-column align-items-center">
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                          {edition.winningTeam && edition.winningTeam.mcs && edition.winningTeam.mcs.map((mc, mcIndex) => (
                            <li key={mcIndex} className="text-center">
                              <Link to={`/profile/${mc.nickName}`}>
                                <img className="img-fluid d-block mx-auto rounded-circle" src={mc.photo} width="75" alt="Profile" />
                              </Link>
                              <p className="mb-0 mc-name">{mc.nickName}</p> {/* Aplicando a classe mc-name aqui */}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="col-9">
                        <h4 className="card-title">{edition.organizationName}</h4>
                        <p className="card-text">{edition.city} - {edition.state}</p>
                          <p className="card-title">{edition.format}</p>
                          <Link className="edition-number-link" to={`/edition/organization/${edition.organizationName}/number/${edition.number}`}>
                            <p className="card-text">nº {edition.number}</p>
                          </Link>
                        <p className="card-text">{new Date(edition.dateEvent).toLocaleDateString()}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        {loading && (
          <div className="text-center py-3">
            <ClipLoader color="#000" loading={loading} size={35} />
          </div>
        )}
      </div>

      <footer>
        <div className="text-center">
          <p className="mb-0">© 2024 Rhymes Score. All rights reserved</p>
        </div>
      </footer>
    </div>
  );
}

export default Home;
